import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchData, fetchSingleton } from '../redux/modules/db';

export default function useLoadDbData() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchData({ collection: 'area' }));
    dispatch(fetchData({ collection: 'brand', query: { fields: ['*', 'exhibitors.*'] } }));
    dispatch(fetchData({ collection: 'category', query: { fields: ['*', 'exhibitors.*'] } }));
    dispatch(fetchData({ collection: 'exhibitor', query: { fields: ['*', 'brands.*', 'categories.*'] } }));
    dispatch(fetchData({ collection: 'floor' }));
    dispatch(fetchData({ collection: 'position' }));
    dispatch(fetchSingleton('settings'));
  }, []);

}
