import styled from 'styled-components';

import { dbBaseSelectors } from '../../app/redux/modules/db';
import { selectExhibitorsByFloorId } from '../../app/redux/selectors/selectExhibitorsByFloorId';
import { useAppSelector } from '../../app/redux/store';
import { Floor } from '../../app/types';
import Heading from '../../app/view/components/Heading';
import ExhibitorTile from './ExhibitorTile';

interface Props {
  floorId: Floor['id'];
}

const Container = styled.div`
  width: auto;
  padding-bottom: 30px;
`;

const Exhibitors = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  position: relative;
`;

export default function ExhibitorsList({ floorId }: Props) {
  const floorName = useAppSelector(state => dbBaseSelectors.floor.selectById(state, floorId)?.name);
  const exhibitors = useAppSelector(state => selectExhibitorsByFloorId(state, floorId, true));

  if (!exhibitors.length) return null;

  return (
    <Container>
      <Heading level={ 3 }>{ floorName }</Heading>
      <Exhibitors>
        {
          exhibitors?.map(({ id }) => (<ExhibitorTile key={ id } exhibitorId={ id } />))
        }
      </Exhibitors>
    </Container>
  );
}
