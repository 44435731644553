import { createSelector } from '@reduxjs/toolkit';

import { dbBaseSelectors } from '../modules/db';
import { selectSettings } from './selectSettings';

export const selectIdeenweltFloor = createSelector(
  selectSettings,
  dbBaseSelectors.floor.selectEntities,
  (settings, floors) => floors[settings?.ideenwelt_floor],
);
