import styled from 'styled-components';

import { selectIdeenweltFloor } from '../../app/redux/selectors/selectIdeenweltFloor';
import { useAppSelector } from '../../app/redux/store';
import Heading from '../../app/view/components/Heading';
import { ReactComponent as Ideenwelt } from './ideenwelt.svg';
import IdeenweltMap from './IdeenweltMap';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 911px;
  align-items: center;
  height: 100%;
  padding-top: 30px;
`;

const H = styled(Heading)`
  align-self: stretch;
  display: flex;
  align-items: center;
`;

const Description = styled.div`
  font-size: 1.6rem;
  font-weight: 300;
`;

const Icon = styled(Ideenwelt)`
  margin-left: 10px;
  position: relative;
  top: -5px;
`;

export default function IdeenweltPage() {
  const floor = useAppSelector(selectIdeenweltFloor);
  return (
    <PageContainer>
      <H level={ 2 }>Ideen-welt <Icon width={ 48 }/></H>
      <Description>
        { floor?.description }
      </Description>
      <IdeenweltMap />
    </PageContainer>
  );
}
