import { PartialItem, QueryMany, TypeOf } from '@directus/sdk';

import { DbCollections, SingletonCollections, SingletonNames } from '../app/types';
import directus from './directus';

const emptyItems: PartialItem<unknown>[] = [];

// returns with empty items array, when query has a filter set but a filter operator is === `undefined`
export async function fetchCollectionData<K extends keyof DbCollections, C = TypeOf<DbCollections, K>>(
  collection: K,
  query: QueryMany<DbCollections[K]> = {},
) {
  // Check if any filter is set with an undefined value.
  // If there is a undefined filter set an empty result is returned by convention.
  const hasUndefinedFilter: boolean = !!query?.filter && Object.values(query.filter)
    .some(field => field && Object.values(field).some(operator => operator === undefined));

  if (!query?.limit) query.limit = -1;

  query.filter = query.filter || {};
  if (!query.filter.status) query.filter.status = { '_eq': 'published' };

  // HACK: due to suboptimal typing of a directus inner type, the type is not properly inferred here.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _items = hasUndefinedFilter ? undefined : await directus?.items(collection).readMany(query as any);
  return (_items?.data || emptyItems) as PartialItem<C>[];
}

export async function fetchSingletonData<K extends SingletonNames, C = TypeOf<SingletonCollections, K>>(collection: K) {
  return directus.singleton(collection).read() as PartialItem<C>;
}
