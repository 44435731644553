type ValueOf<T> = T[keyof T];

// Since I find the typing in record not as convenient I return an object type.
// The record type could be returned as well. Just omit the return type definition.
export function mapObject<IN extends Record<string, unknown>, VAL>(
  map: (v: ValueOf<IN>, k: keyof IN) => VAL,
  obj: IN,
): { [K in keyof IN]: VAL } {
  const result = {} as Record<keyof IN, VAL>;
  for (const i in obj) {
    if (i in obj) {
      result[i] = map(obj[i], i);
    }
  }

  return result;
}

export function isString(v: unknown): v is string {
  return typeof v === 'string';
}
