import './index.css';

import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { store } from './app/redux/store';
import App from './app/view/app/App';
import DirectusProvider from './modules/directus/DirectusProvider';
import reportWebVitals from './reportWebVitals';

render(
  <DirectusProvider>
    <Provider store={ store }>
      <Router>
        <App />
      </Router>
    </Provider>
  </DirectusProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
