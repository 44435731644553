import { createSelector } from '@reduxjs/toolkit';

import { dbBaseSelectors } from '../modules/db';
import { selectCurrentPosition } from './selectCurrentPosition';

export const selectCurrentHomeMap = createSelector(
  dbBaseSelectors.floor.selectEntities,
  selectCurrentPosition,
  state => state.app.selectedFloor,
  (floors, position, selectedFloor) => {
    if (position?.floor === selectedFloor) return position?.map;
    return floors[selectedFloor]?.map;
  },
);
