import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Category, Floor } from '../../types';

interface State {
  selectedFloor: string;
  displayLocationName: string;
  filterCategoryIds: Category['id'][];
  filterFirstLetter: string;
  filterSearchQuery: string;
  showSearchBox: boolean;
  showScrollButtons: boolean;
}

const initialState: State = {
  selectedFloor: '',
  displayLocationName: '',
  filterCategoryIds: [],
  filterFirstLetter: '',
  filterSearchQuery: '',
  showSearchBox: false,
  showScrollButtons: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setFloor: (state, action: PayloadAction<Floor['id']>) => {
      state.selectedFloor = action.payload;
    },
    setDislayLocationName: (state, action: PayloadAction<string>) => {
      state.displayLocationName = action.payload;
    },
    setFilterCategoryIds: (state, action: PayloadAction<Category['id'][]>) => {
      state.filterFirstLetter = '';
      state.filterCategoryIds = action.payload;
    },
    setFilterFirstLetter: (state, action: PayloadAction<State['filterFirstLetter']>) => {
      if (state.filterFirstLetter === action.payload) state.filterFirstLetter = '';
      else state.filterFirstLetter = action.payload;
    },
    setFilterSearchQuery: (state, action: PayloadAction<State['filterSearchQuery']>) => {
      state.filterSearchQuery = action.payload;
    },
    showSearchBox: (state, action: PayloadAction<State['showSearchBox']>) => {
      state.showSearchBox = action.payload;
    },
    showScrollButtons: (state, action: PayloadAction<State['showScrollButtons']>) => {
      state.showScrollButtons = action.payload;
    },
  },
});

export default appSlice.reducer;

export const {
  setDislayLocationName,
  setFloor,
  setFilterCategoryIds,
  setFilterFirstLetter,
  showSearchBox,
  setFilterSearchQuery,
  showScrollButtons,
} = appSlice.actions;
