import styled from 'styled-components';

import { useAppSelector } from '../../app/redux/store';
import { isBrand } from '../../app/types';
import ExhibitorTile from '../ExhibitorsPage/ExhibitorTile';
import BrandTile from './BrandTile';
import { selectFilteredBrandsPageEntries } from './selectFilteredBrandsPageEntries';

const Container = styled.div`
  width: auto;
  padding-bottom: 30px;
`;

const Brands = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  position: relative;
`;

export default function BrandList() {
  const items = useAppSelector(state => selectFilteredBrandsPageEntries(state, {}));

  if (!items.length) return null;

  return (
    <Container>
      <Brands>
        {
          items.map(item => isBrand(item)
            ? (<BrandTile key={ item.id } id={ item.id } />)
            : (<ExhibitorTile key={ item.id } exhibitorId={ item.id } />),
          )
        }
      </Brands>
    </Container>
  );
}
