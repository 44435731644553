import { createSelector } from '@reduxjs/toolkit';

import { dbBaseSelectors } from '../../../../redux/modules/db';
import { selectFilterRootCategoryId } from '../TopicNavigation/selectFilterRootCategoryId';

export const selectRootCategoryHeaderImage = createSelector(
  selectFilterRootCategoryId,
  dbBaseSelectors.category.selectEntities,
  (categoryId, categories) => {
    if (!categoryId || !categories[categoryId]) return undefined;

    return categories[categoryId]?.header_image;
  },
);
