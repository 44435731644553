import { RefObject, TouchEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

import { selectShowScrollButtons } from '../../../../redux/selectors/selectShowScrollButtons';
import { useAppSelector } from '../../../../redux/store';
import { ReactComponent as ScrollDown } from './scroll-down.svg';
import { ReactComponent as ScrollUp } from './scroll-up.svg';

const ButtonsWrapper = styled.div`
  position: fixed;
  right: 0;
  top: 50vh;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  overflow: hidden;
`;

const Button = styled.button`
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  padding: 20px;
  user-select: none;
  border: 0;
  outline: 0;
`;

interface Props {
  contentRef: RefObject<HTMLDivElement>;
}

export default function ScrollButtons({ contentRef }: Props) {
  const [scroll, setScroll] = useState<'up' | 'down' | null>(null);
  const visible = useAppSelector(selectShowScrollButtons);

  const createScrollHandler = (direction: typeof scroll) => (event?: TouchEvent<HTMLButtonElement>) => {
    if (event) event.stopPropagation();

    setScroll(direction);
  };

  useEffect(() => {
    if (!contentRef.current || !scroll) return;
    const dy = scroll === 'up' ? -3 : 3;

    const interval = setInterval(() => {
      if (!contentRef.current) return;
      contentRef.current.scrollBy(0, dy);
    }, 10);

    return () => clearInterval(interval);
  }, [scroll]);

  if (!visible) return null;

  return (
    <ButtonsWrapper>
      <Button
        onContextMenu={ (e) => e.preventDefault() }
        onTouchStart={ createScrollHandler('up') }
        onTouchEnd={ createScrollHandler(null) }
      ><ScrollUp /></Button>
      <Button
        onContextMenu={ (e) => e.preventDefault() }
        onTouchStart={ createScrollHandler('down') }
        onTouchEnd={ createScrollHandler(null) }
      ><ScrollDown /></Button>
    </ButtonsWrapper>
  );
}
