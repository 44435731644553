import { createSelector } from '@reduxjs/toolkit';

import { selectCategoryIdsChildrenIdsMap } from './selectCategoryIdsChildrenIdsMap';
import { selectFilterCategoryIds } from './selectFilterCategoryIds';

export const selectImplicitFilterCategoryIds = createSelector(
  selectFilterCategoryIds,
  selectCategoryIdsChildrenIdsMap,
  (filterCategoryIds, categoriesChildrenIdsMap) => {
    const implicitFilterCategoryIds: Set<string> = new Set(filterCategoryIds);

    filterCategoryIds.forEach(categoryId => {
      // don't consider an empty category
      if (!categoryId) return;
      const categoryChildren = categoriesChildrenIdsMap[categoryId];
      // don't consider a category without children
      if (!categoryChildren?.length) return;
      // don't consider a category that has at least one child filtered
      if (categoryChildren.some(id => filterCategoryIds.includes(id))) return;

      // add all children for the categories, when there is not a single child already included
      categoryChildren.forEach(implicitFilterCategoryIds.add.bind(implicitFilterCategoryIds));
    });

    return [...implicitFilterCategoryIds.values()];
  },
);
