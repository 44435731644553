import styled from 'styled-components';

import { selectIdeenweltFloor } from '../../app/redux/selectors/selectIdeenweltFloor';
import { useAppSelector } from '../../app/redux/store';
import BoothAreasOverlay from '../../app/view/components/BoothAreasOverlay';
import DirectusImage from '../directus/DirectusImage';
import useFile from '../directus/useFile';

const FlexContainer = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
`;

const Container = styled.div`
  position: relative;
  width: 911px;
  margin: 0vw auto;

  > img {
    width: 911px;
    object-fit: contain;
  }
`;

export default function IdeenweltMap() {
  const floor = useAppSelector(selectIdeenweltFloor);
  const image = useFile(floor?.map);

  if (!image?.file) return null;

  return (
    <FlexContainer>
      <Container>
        { image.file &&
          <DirectusImage asset={ image.file } width={ 864 } />
        }
        <BoothAreasOverlay />
      </Container>
    </FlexContainer>
  );
}
