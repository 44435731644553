import { useIdleTimer } from 'react-idle-timer';
import { useHistory, useLocation } from 'react-router-dom';

import { selectSettings } from '../redux/selectors/selectSettings';
import { useAppSelector } from '../redux/store';
import useClearFilters from './useClearFilters';

export default function useIdleTimeoutRedirect() {
  const loc = useLocation();
  const history = useHistory();
  const clearFilters = useClearFilters();
  const timeout = useAppSelector(selectSettings)?.idle_timeout;

  useIdleTimer({
    startOnMount: true,
    timeout: (timeout || 10) * 1000,
    onIdle: () => {
      clearFilters();
      if (loc.pathname === '/') return;
      history.replace('/');
    },
    onActive:  () => {
      console.log('active');
    },
  });

}
