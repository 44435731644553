import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import DirectusImage from '../../../../../modules/directus/DirectusImage';
import useFile from '../../../../../modules/directus/useFile';
import { useAppSelector } from '../../../../redux/store';
import { IDEENWELT_ROUTE } from '../../../../types';
import { selectDefaultHeaderImage } from './selectDefaultHeaderImage';
import { selectIdeenweltHeaderImage } from './selectIdeenweltHeaderImage';
import { selectRootCategoryHeaderImage } from './selectRootCategoryHeaderImage';

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 318px;

  > img {
    width: 100vw;
    height: 318px;
    object-fit: cover;
  }
`;

export default function ImageHeader() {
  const isIdeenwelt = !!useRouteMatch(IDEENWELT_ROUTE);
  const defaultHeaderImage = useAppSelector(selectDefaultHeaderImage);
  const ideenweltHeaderImage = useAppSelector(selectIdeenweltHeaderImage);
  const rootCategoryHeaderImage = useAppSelector(selectRootCategoryHeaderImage);

  const imageId = (isIdeenwelt ? ideenweltHeaderImage : rootCategoryHeaderImage) || defaultHeaderImage;
  const image = useFile(imageId);

  return (
    <Container>
      <DirectusImage width={ 1080 } asset={ image.file } fallbackSrc="/dummy-images/header_bauarena.png" />
    </Container>
  );
}
