import { Route, Switch } from 'react-router-dom';

import BrandDetailsPage from '../../../modules/BrandDetailsPage';
import BrandsPage from '../../../modules/BrandsPage';
import ExhibitorDetailsPage from '../../../modules/ExhibitorDetailsPage';
import ExhibitorsPage from '../../../modules/ExhibitorsPage';
import FloorsOverviewPage from '../../../modules/FloorsOverviewPage';
import HomePage from '../../../modules/HomePage';
import IdeenweltPage from '../../../modules/IdeenweltPage';
import useIdleTimeoutRedirect from '../../hooks/useIdleTimeoutRedirect';
import useLoadDbData from '../../hooks/useLoadDbData';
import { IDEENWELT_ROUTE } from '../../types';
import Layout from '../components/Layout';
import useLoadDisplayLocation from './useLoadDisplayLocation';

function App() {
  useLoadDbData();
  useIdleTimeoutRedirect();
  useLoadDisplayLocation();

  return (
    <Layout>
      <Switch>
        <Route path="/brands/:brandId">
          <BrandDetailsPage />
        </Route>
        <Route path="/brands">
          <BrandsPage />
        </Route>
        <Route path="/floors">
          <FloorsOverviewPage />
        </Route>
        <Route path="/exhibitors/:exhibitorId">
          <ExhibitorDetailsPage />
        </Route>
        <Route path="/exhibitors">
          <ExhibitorsPage />
        </Route>
        <Route path="/search">
          <ExhibitorsPage />
        </Route>
        <Route path={ IDEENWELT_ROUTE }>
          <IdeenweltPage />
        </Route>
        <Route path="/">
          <HomePage />
        </Route>
      </Switch>
    </Layout>
  );
}

export default App;
