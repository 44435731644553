import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import useShowScrollButtons from '../../app/hooks/useShowScrollButtons';
import { dbBaseSelectors } from '../../app/redux/modules/db';
import { selectMainFloorIds } from '../../app/redux/selectors/selectMainFloorIds';
import { useAppSelector } from '../../app/redux/store';
import { Floor } from '../../app/types';
import Heading from '../../app/view/components/Heading';
import BrandTile from '../BrandsPage/BrandTile';
import ExhibitorsList from './ExhibitorsList';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 911px;
  font-size: 1.6rem;
`;

const BrandNotFound = () => (
  <PageContainer>
    <Heading level={ 2 }>404 Brand Not Found</Heading>
  </PageContainer>
);

const BrandWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  position: relative;
  margin-bottom: 30px;
`;

const FloorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const SubHeading = styled(Heading)`
  border: none;
  margin-bottom: -20px;
`;

export default function BrandDetailsPage () {
  useShowScrollButtons(true);
  const { brandId } = useParams<{ brandId: string }>();
  const brand = useAppSelector(state => dbBaseSelectors.brand.selectById(state, brandId));
  const floorIds = useAppSelector(selectMainFloorIds);

  const exibitorIdList = useMemo(
    () => brand?.exhibitors?.map(i => i.exhibitor_id),
    [brand],
  );

  if (!brand) {
    return (<BrandNotFound />);
  }

  return (
    <PageContainer>
      <Heading level={ 2 }>{ brand.name }</Heading>

      <BrandWrapper>
        <BrandTile id={ brand.id } />
      </BrandWrapper>

      { !!exibitorIdList?.length && (
        <>
          <SubHeading level={ 2 }>Verfügbare Aussteller:</SubHeading>

          <FloorsWrapper>
            { floorIds.map(id => (
              <ExhibitorsList
                key={ id } floorId={ id as Floor['id'] }
                idList={ exibitorIdList }
              />
            )) }
          </FloorsWrapper>
        </>
      ) }

    </PageContainer>
  );
}
