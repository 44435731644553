import { createSelector } from '@reduxjs/toolkit';

import { dbBaseSelectors } from '../modules/db';
import { selectSettings } from './selectSettings';

export const selectMainFloorIds = createSelector(
  selectSettings,
  dbBaseSelectors.floor.selectIds,
  (settings, floorIds) => floorIds.filter(id => id !== settings?.ideenwelt_floor),
);
