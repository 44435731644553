import { useEffect } from 'react';

import { showScrollButtons } from '../redux/modules/app';
import { useAppDispatch } from '../redux/store';

export default function useShowScrollButtons(show: boolean) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(showScrollButtons(show));

    return () => {
      dispatch(showScrollButtons(false));
    };
  }, [show]);
}
