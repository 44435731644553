import { Directus, DirectusOptions } from '@directus/sdk';
import { createContext, PropsWithChildren, useMemo } from 'react';

import { DbCollections } from '../../app/types';
import env from '../../env';

interface Props {
  options?: DirectusOptions;
}

interface DirectusContextType {
  apiUrl: string;
  directus: Directus<DbCollections>;
}

export const DirectusContext = createContext<DirectusContextType | undefined>(undefined);

export default function DirectusProvider({ children, options }: PropsWithChildren<Props>) {
  if (!env.REACT_APP_API_URL) throw new Error('Directus API Url missing. Please provide via `REACT_APP_API_URL` env variable');
  const apiUrl = env.REACT_APP_API_URL || '';

  const value = useMemo<DirectusContextType>(() => ({
    apiUrl,
    directus: new Directus<DbCollections>(apiUrl, options),
  }), [apiUrl]);

  return <DirectusContext.Provider value={ value }>{ children }</DirectusContext.Provider>;
}
