import { createSelector } from '@reduxjs/toolkit';

import { dbBaseSelectors } from '../../../redux/modules/db';
import { RootState } from '../../../redux/store';
import { Area, Exhibitor, Floor } from '../../../types';

export interface Booth {
  area: Area;
  exhibitor: Exhibitor;
}

export const selectBoothsByFloor = createSelector(
  dbBaseSelectors.area.selectAll,
  dbBaseSelectors.exhibitor.selectEntities,
  (_state: RootState, floorId: Floor['id']) => floorId,

  (areas, exhibitors, floorId) => areas
    .filter(areas => !!areas.exhibitor)
    .map(area => ({ area, exhibitor: exhibitors[area.exhibitor] } as Booth))
    .filter(brand => brand.exhibitor && brand.exhibitor?.floor === floorId),
);
