import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  borderRadius: '5px',
  palette: {
    common: {
      black: 'hsl(0,0%,0%)',
      white: 'hsl(0,0%,100%)',
      darkText: 'hsl(0,0%,35%)',
      grayText: 'hsl(0,0%,50%)',
      grayBg: 'hsl(0,0%,72.5%)',
    },
    primary: {
      // main: '#DD3149',
      main: 'hsl(351.6, 71.7%, 52.9%)',
      lighter: 'hsl(351.6, 71.7%, 62.9%)',
      darker: 'hsl(351.6, 71.7%, 42.9%)',
      contrastText: 'hsl(0,0%,100%)',
    },
    secondary: {
      // main: '#EFEFEF',
      main: 'hsl(0,0%,93.7%)',
      lighter: 'hsl(0,0%,97.3%)',
      darker: 'hsl(0,0%,83.7%)',
      contrastText: '#DD3149',
    },
    livingroom: {
      // main: '#C5BA9E',
      main: 'hsl(43.1,25.2%,69.6%)',
      lighter: 'hsl(43.1,25.2%,79.6%)',
      darker: 'hsl(43.1,25.2%,59.6%)',
      contrastText: 'hsl(42, 18%, 29%)',
    },
    bathroom: {
      // main: '#8DA8C3',
      main: 'hsl(210,31%,65.9%)',
      lighter: 'hsl(210,31%,75.9%)',
      darker: 'hsl(210,31%,55.9%)',
      contrastText: 'hsl(208, 23%, 28%)',
    },
    kitchen: {
      // main: '#C37978',
      main: 'hsl(0.8,38.5%,61.8%)',
      lighter: 'hsl(0.8,38.5%,71.8%)',
      darker: 'hsl(0.8,38.5%,51.8%)',
      contrastText: 'hsl(0, 26%, 32%)',
    },
    building: {
      // main: '#C5B86C',
      main: 'hsl(51, 43%, 60%)',
      lighter: 'hsl(93.8,28.3%,65.7%)',
      darker: 'hsl(93.8,28.3%,45.7%)',
      contrastText: 'hsl(51, 29%, 28%)',
    },
    garden: {
      // main: '#6BA96E',
      main: 'hsl(123, 26%, 54%)',
      lighter: 'hsl(93.8,28.3%,65.7%)',
      darker: 'hsl(93.8,28.3%,45.7%)',
      contrastText: 'hsl(51, 29%, 28%)',
    },
  },
};
