
import { dbBaseSelectors } from '../../app/redux/modules/db';
import { useAppSelector } from '../../app/redux/store';
import ItemTile from '../../app/view/components/ItemTile';
import useFile from '../directus/useFile';

interface Props {
  exhibitorId: string;
}

export default function ExhibitorTile({ exhibitorId }: Props) {
  const exhibitor = useAppSelector(state => dbBaseSelectors.exhibitor.selectById(state, exhibitorId));
  const logo = useFile(exhibitor?.logo_image);

  if (!exhibitor) return null;
  return <ItemTile to={ `/exhibitors/${exhibitorId}` } image={ logo.file } name={ `EXHIBITOR: ${exhibitor.name}` } />;
}
