import { QueryMany, TypeOf } from '@directus/sdk';
import {
  createAsyncThunk, createEntityAdapter,
  createSlice, EntitySelectors, EntityState, isRejected,
} from '@reduxjs/toolkit';

import { fetchCollectionData, fetchSingletonData } from '../../../api/fetch-collection-data';
import { CollectionNames, DbCollections, mapCollections, SingletonCollections, SingletonNames } from '../../types';
import { RootState } from '../store';

type CollectionTypes = TypeOf<DbCollections, keyof DbCollections>;

interface FetchParams {
  collection: CollectionNames;
  query?: QueryMany<DbCollections[CollectionNames]>;
}

export const fetchData = createAsyncThunk('db', ({ collection, query }: FetchParams) => fetchCollectionData(collection, query));
export const fetchSingleton = createAsyncThunk('db-singleton', (collection: SingletonNames) => fetchSingletonData(collection));

const dbAdapter = createEntityAdapter<CollectionTypes>();

type State = {
  [key in CollectionNames]: EntityState<DbCollections[key]>;
} & {
  [key in SingletonNames]: TypeOf<SingletonCollections, key>;
};

export const initialState = mapCollections(() => dbAdapter.getInitialState()) as State;

export const dbSlice = createSlice({
  name: 'db',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchData.fulfilled, (state, action) => {
      const collection = action.meta.arg.collection;
      dbAdapter.upsertMany(state[collection], action.payload as DbCollections[typeof collection][]);
    });
    builder.addCase(fetchSingleton.fulfilled, (state, action) => {
      const collection = action.meta.arg;
      state[collection] = action.payload as SingletonCollections[typeof collection];
    });
    builder.addMatcher(isRejected(fetchData, fetchSingleton), (_state, action) => {
      console.log('error', action.error);
    });
  },
});

export default dbSlice.reducer;

type Selectors = {
  [key in CollectionNames]: EntitySelectors<DbCollections[key], RootState>;
};

export const dbBaseSelectors = mapCollections(name => dbAdapter.getSelectors(state => state.db[name])) as Selectors;
