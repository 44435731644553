import { Link } from 'react-router-dom';
import styled from 'styled-components';

import DirectusImage from '../../../modules/directus/DirectusImage';
import { DirectusFile } from '../../../modules/directus/useFile';
import { selectShowOverlays } from '../../redux/selectors/selectShowOverlays';
import { useAppSelector } from '../../redux/store';

interface Props {
  to: string;
  image?: DirectusFile | null;
  name: string;
  className?: string;
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 90px;
  background-color: #EFEFEF;
`;

const Caption = styled.div`
  position: absolute;
  width: 100%;
  background-color: rgba(100,200,100,0.5);
  top: 0;
  left: 0;
`;

const StyledDirectusImage = styled(DirectusImage)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export default function ItemTile({ to, name, image, className }: Props) {
  const showOverlays = useAppSelector(selectShowOverlays);

  return (
    <Container className={ className }>
      <Link to={ to }>
        <StyledDirectusImage
          asset={ image }
          fallbackSrc="https://picsum.photos/seed/picsum/200/90"
          title={ name }
          fit="contain"
          height={ 90 }
        />
      </Link>
      { showOverlays &&
        <Caption style={ { backgroundColor: name.startsWith('BRAND') ? 'rgba(100,100,200,0.5)' : 'rgba(100,200,100,0.5)' } }>
          { name }
        </Caption>
      }
    </Container>
  );
}
