import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { setFilterCategoryIds } from '../../../../redux/modules/app';
import { selectFilterCategoryIds } from '../../../../redux/selectors/selectFilterCategoryIds';
import { selectRootCategories } from '../../../../redux/selectors/selectRootCategories';
import { selectShowSearchBox } from '../../../../redux/selectors/selectShowSearchBox';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { Category } from '../../../../types';
import SubcategoryFilter from './SubcategoryFilter';
import { ReactComponent as TopicBath } from './topic-bath.svg';
import { ReactComponent as TopicBuilding } from './topic-building.svg';
import { ReactComponent as TopicGarden } from './topic-garden.svg';
import { ReactComponent as TopicKitchen } from './topic-kitchen.svg';
import { ReactComponent as TopicLiving } from './topic-living.svg';

const Nav = styled.nav`
  display: block;
  background-color: transparent;
`;

const Ul = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: 0;
`;

const Li = styled.li`
  display: block;
  margin: 0;
  flex: 1 0 auto;
  color: #ffffff;

  &.active {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    color: #000000;
    z-index: 1;

    span {
      mix-blend-mode: overlay;
    }
  }

  &:nth-child(1) {
    background-color: ${({ theme }) => theme.palette.livingroom.main};
  }

  &:nth-child(2) {
    background-color: ${({ theme }) => theme.palette.bathroom.main};
  }

  &:nth-child(3) {
    background-color: ${({ theme }) => theme.palette.kitchen.main};
  }

  &:nth-child(4) {
    background-color: ${({ theme }) => theme.palette.building.main};
  }

  &:nth-child(5) {
    background-color: ${({ theme }) => theme.palette.garden.main};
  }
`;

const NavLink = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  padding-top: 2px;
  color: inherit;
  text-align: center;
  justify-content: space-evenly;
  height: 112px;
`;

const NavLinkIcon = styled.span`
  width: 96px;
  height: 48px;

  svg {
    width: 96px;
    height: 48px;
  }
`;

const NavLinkLabel = styled.span`
  font-size: 1.5rem;
  color: inherit;
`;

export default function TopicNavigation() {
  const dispatch = useAppDispatch();
  const categories = useAppSelector(selectRootCategories);
  const filterCategoryIds = useAppSelector(selectFilterCategoryIds);
  const showSearchBox = useAppSelector(selectShowSearchBox);
  const history = useHistory();
  const isExhibitorsPage = history.location.pathname === '/exhibitors';

  const handleFilter = (id: Category['id']) => {
    if (!id) return;
    if (!isExhibitorsPage) history.push('/exhibitors');
    dispatch(setFilterCategoryIds(filterCategoryIds.includes(id) ? [] : [id]));
  };

  const NavLinkIconSvg = ({ name }: { name:string }) => {
    if (name === 'Wohnen') {
      return <TopicLiving/>;
    }

    if (name === 'Bad') {
      return <TopicBath/>;
    }

    if (name === 'Küche') {
      return <TopicKitchen/>;
    }

    if (name === 'Gebäude') {
      return <TopicBuilding/>;
    }

    if (name === 'Garten') {
      return <TopicGarden/>;
    }

    return null;
  };

  if (showSearchBox) return null;

  return (
    <>
      { isExhibitorsPage && <SubcategoryFilter /> }
      <Nav>
        <Ul>
          {
            categories?.map(({ name, id }) => (
              <Li key={ id } className={ clsx({ active: filterCategoryIds.includes(id) }) }>
                <NavLink onClick={ () => handleFilter(id) }>
                  <NavLinkIcon>
                    <NavLinkIconSvg name={ name }/>
                  </NavLinkIcon>
                  <NavLinkLabel>{ name }</NavLinkLabel>
                </NavLink>
              </Li>
            ))
          }
        </Ul>
      </Nav>
    </>
  );
}
