import { memo, useContext, useMemo } from 'react';

import { DirectusContext } from './DirectusProvider';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DirectusAsset = string | { id: string } & Record<string, any>;

interface Props {
  /** The asset as `string` or `object` with an `id` property of type `string`. */
  asset: DirectusAsset | null | undefined,
  // Optional title. If not set, the title is fetched from the cms asset
  title?: string | null | undefined,
  /** The width of the thumbnail in pixels. */
  width?: number,
  /** The height of the thumbnail in pixels. */
  height?: number,
  /** The quality of the thumbnail (1 to 100). */
  quality?: number,
  /** The fit of the thumbnail while always preserving the aspect ratio. */
  fit?: 'cover' | 'contain' | 'inside' | 'outside',
  // Obtional fallback src, in case no image is found
  fallbackSrc?: string;
  className?: string;
}

function DirectusImage({ asset, title, fallbackSrc, className, ...params }: Props) {
  const apiUrl = useContext(DirectusContext)?.apiUrl;
  const assetTitle = useMemo(() => title || (typeof asset === 'object' ? asset?.title as string : ''), [title, asset]);

  // set default value for quality to 100%
  if (!params.quality) params.quality = 100;

  const url = useMemo(() => {
    const assetId = typeof asset === 'object' ? asset?.id : asset;
    if (!assetId) return fallbackSrc || '';

    const searchParams = (new URLSearchParams(params as Record<string, string>)).toString();
    return `${ apiUrl }/assets/${ assetId }?${ searchParams }`;
  }, [apiUrl, asset, params]);

  return <img className={ className } src={ url } alt={ assetTitle } />;
}

export default memo(DirectusImage);
