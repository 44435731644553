import { useCallback } from 'react';
import styled from 'styled-components';

import { setFilterCategoryIds } from '../../../redux/modules/app';
import { selectFilterCategoryIds } from '../../../redux/selectors/selectFilterCategoryIds';
import { selectRootCategories } from '../../../redux/selectors/selectRootCategories';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { ReactComponent as TopicFilter } from './topic-filter.svg';

const Container = styled.div`
  background-color: #8B8B8B;
  padding: 10px 0px;
  position: relative;
`;

const CategoryListContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0rem 8rem;
  min-height: 40px;
`;

const CategoryListIcon = styled.div`
  position: absolute;
  left: 2rem;
  top: 17px;
  height: 48px;
  width: 48px;
`;

interface ICategoryItem {
  readonly active: boolean;
}

const CategoryItem = styled.button<ICategoryItem>`
  display: inline-block;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  color: ${({ active, theme }) => active ? '#ffffff' : theme.palette.secondary.contrastText};
  background-color: ${({ active, theme }) => active ? theme.palette.primary.main : theme.palette.secondary.main};
  border: 1px solid ${({ theme }) => theme.palette.common.darkText};
  font-size: 2rem;
  padding: 0.5rem 2rem;
  margin: 0 4px 4px 0;
  border-radius: 4px;
`;

export default function CategoryFilter() {
  const categoryList = useAppSelector(selectRootCategories);
  const categoryIdList = useAppSelector(selectFilterCategoryIds);
  const dispatch = useAppDispatch();

  const onFilterClick = useCallback(categoryId => {
    if (categoryIdList.includes(categoryId)) {
      dispatch(setFilterCategoryIds([]));
    }
    else {
      dispatch(setFilterCategoryIds([categoryId]));
    }
  }, [categoryIdList]);

  return (
    <Container>
      <CategoryListIcon>
        <TopicFilter width={ 48 } height={ 48 }/>
      </CategoryListIcon>
      <CategoryListContainer>
        { categoryList.map(category => (
          <CategoryItem
            active={ !!categoryIdList.includes(category.id) }
            key={ category.id }
            onClick={ () => onFilterClick(category.id) }
          >
            { category.name }
          </CategoryItem>
        )) }
      </CategoryListContainer>
    </Container>
  );
}
