import { createSelector } from '@reduxjs/toolkit';

import { dbBaseSelectors } from '../modules/db';
import { selectSettings } from './selectSettings';

export const selectMainFloors = createSelector(
  selectSettings,
  dbBaseSelectors.floor.selectAll,
  (settings, floors) => floors
    .filter(floor => floor.id !== settings?.ideenwelt_floor),
);
