import { createSelector } from '@reduxjs/toolkit';
import { uniq } from 'lodash';

import { selectFilteredBrandsPageEntries } from '../../../modules/BrandsPage/selectFilteredBrandsPageEntries';
import { RootState } from '../store';

export const selectBrandsLetters = createSelector(
  (r: RootState) => selectFilteredBrandsPageEntries(r, {}),
  (brands) => uniq(brands.map(({ name }) => name.charAt(0).toLocaleLowerCase())).sort((a, b) => a.localeCompare(b)),
);
