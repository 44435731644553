
import { dbBaseSelectors } from '../../app/redux/modules/db';
import { useAppSelector } from '../../app/redux/store';
import ItemTile from '../../app/view/components/ItemTile';
import useFile from '../directus/useFile';

interface Props {
  id: string;
  className?: string;
}

export default function BrandTile({ id, className }: Props) {
  const brand = useAppSelector(state => dbBaseSelectors.brand.selectById(state, id));
  const logo = useFile(brand?.image);

  if (!brand) return null;
  return <ItemTile className={ className } to={ `/brands/${brand.id}` } image={ logo.file } name={ `BRAND: ${brand.name}` } />;
}
