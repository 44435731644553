import styled from 'styled-components';

import useShowScrollButtons from '../../app/hooks/useShowScrollButtons';
import { selectMainFloorIds } from '../../app/redux/selectors/selectMainFloorIds';
import { useAppSelector } from '../../app/redux/store';
import { Floor } from '../../app/types';
import Heading from '../../app/view/components/Heading';
import FloorExhibitorsList from './FloorExhibitorsList';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 911px;
`;

const FloorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  font-weight: 300;
`;

const FloorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export default function FloorsOverviewPage() {
  const floorIds = useAppSelector(selectMainFloorIds);
  useShowScrollButtons(true);

  return (
    <PageContainer>
      <FloorsContainer>
        <Heading level={ 2 }>Überblick Alle Etagen</Heading>
        <FloorsWrapper>
          {
            floorIds.map(id => (<FloorExhibitorsList key={ id } floor={ id as Floor['id'] } />))
          }
        </FloorsWrapper>
      </FloorsContainer>
    </PageContainer>
  );
}
