import { useState } from 'react';
import { useAsyncEffect } from 'use-async-effect';

import useDirectus from './useDirectus';

export interface DirectusFile {
  charset?: string | null;
  description?: string | null;
  duration?: string | null;
  embed?: string | null;
  filename_disk: string;
  filename_download: string;
  filesize: string;
  folder: string | null;
  height?: number;
  id: string;
  location: string | null;
  metadata: string | null;
  modified_by: string | null;
  modified_on: string | null;
  storage: string | null;
  tags: string | null;
  title: string | null;
  type: string | null;
  uploaded_by: string | null;
  uploaded_on: string | null;
  width?: number
}

interface State {
  loading?: boolean;
  error: string | undefined;
  file: DirectusFile | undefined | null;
}

const initFileInfo: State = {
  loading: false,
  error: undefined,
  file: undefined,
};

export default function useFile(id: string | undefined) {
  const directus = useDirectus();
  const [fileInfo, setFileInfo] = useState(initFileInfo);

  useAsyncEffect(async (isMounted) => {
    if (fileInfo.loading === true) return;

    if (!id) return setFileInfo(initFileInfo);

    const newFileInfo: State = { ...initFileInfo };
    try {
      setFileInfo({ ...initFileInfo, loading: true });
      // HACK: due to suboptimal typing of a directus inner type, the type is not properly inferred here.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const _item = await directus?.items('directus_files').readOne(id);
      newFileInfo.file = _item as DirectusFile || null;
      newFileInfo.error = undefined;
    }
    catch (err) {
      newFileInfo.file = null;
      newFileInfo.error = (err as Error)?.message || err as string || 'an error ocurred';
    }

    newFileInfo.loading = false;
    if (!isMounted()) return;
    setFileInfo(newFileInfo);
  }, [directus, id]);

  return fileInfo;
}
