import clsx from 'clsx';
import { MouseEvent, ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import useClearFilters from '../../../../hooks/useClearFilters';
import { showSearchBox } from '../../../../redux/modules/app';
import { selectShowSearchBox } from '../../../../redux/selectors/selectShowSearchBox';
import { ReactComponent as NavBack } from './nav-back.svg';
import { ReactComponent as NavBrands } from './nav-brands.svg';
import { ReactComponent as NavBrandsActive } from './nav-brands-active.svg';
import { ReactComponent as NavExhibitors } from './nav-exhibitors.svg';
import { ReactComponent as NavExhibitorsActive } from './nav-exhibitors-active.svg';
import { ReactComponent as NavSearch } from './nav-search.svg';
import { ReactComponent as NavSearchActive } from './nav-search-active.svg';
import { ReactComponent as NavStart } from './nav-start.svg';
import { ReactComponent as NavStartActive } from './nav-start-active.svg';

const Nav = styled.nav`
  display: block;
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;

const Ul = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0;
  padding: 0;
`;

const Li = styled.li`
  display: block;
  margin: 0;
  flex: 1 0 22%;
  color: ${({ theme }) => theme.palette.common.darkText};

  &:first-child {
    flex: 1 0 12%;
  }

  &.active {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: ${({ theme }) => theme.palette.secondary.lighter};
  }
`;

const NavLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  padding: 1.5em;
  color: inherit;
  text-align: center;
`;

const NavLinkIcon = styled.span`
  width: 48px;
  height: 48px;

  svg {
    width: 48px;
    height: 48px;
  }
`;

const NavLinkLabel = styled.span`
  margin-top: 1rem;
  font-size: 1.5rem;
  color: inherit;
`;

const NavLinkBack = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  margin: 0;
  outline: 0;
  padding: 1.5em;
  color: ${({ theme }) => theme.palette.common.grayText};
  text-align: center;
  border: 0;
  border-right: 2px solid #ffffff;

  &[disabled] {
    opacity: 0.5;
    border-right: 2px solid #ffffff;
    border-right-color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

interface FooterNavigationButtonProps {
  label: string;
  route: string;
  icon: ReactElement;
  iconActive: ReactElement;
}

export default function FooterNavigation() {
  const loc = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const isSearchBoxOpen = useSelector(selectShowSearchBox);

  const clearFilters = useClearFilters();

  const onSearchLinkClick = useCallback((e: MouseEvent) => {
    // don't perform router navigation
    e.preventDefault();

    clearFilters();
    dispatch(showSearchBox(true));

    history.replace('/exhibitors');
  }, [ isSearchBoxOpen, dispatch, history ]);

  const FooterNavigationButton = ({ label, route, icon, iconActive }: FooterNavigationButtonProps) => {
    return (
      <Li className={ clsx({ active: loc.pathname === route && !isSearchBoxOpen }) }>
        <NavLink to={ route } onClick={ clearFilters }>
          <NavLinkIcon>{ loc.pathname === route && !isSearchBoxOpen ? (iconActive) : (icon) }</NavLinkIcon>
          <NavLinkLabel>{ label }</NavLinkLabel>
        </NavLink>
      </Li>
    );
  };

  const back = useCallback(() => {
    clearFilters();
    history.goBack();
  }, [ history ]);

  return (
    <Nav>
      <Ul>
        <Li>
          <NavLinkBack disabled={ loc.pathname === '/' } onClick={ back }>
            <NavLinkIcon>
              <NavBack/>
            </NavLinkIcon>
            <NavLinkLabel>zurück</NavLinkLabel>
          </NavLinkBack>
        </Li>
        <FooterNavigationButton
          label="Start"
          route="/"
          icon={ <NavStart /> }
          iconActive={ <NavStartActive /> }
        />
        <FooterNavigationButton
          label="Aussteller"
          route="/exhibitors"
          icon={ <NavExhibitors /> }
          iconActive={ <NavExhibitorsActive /> }
        />
        <FooterNavigationButton
          label="Marken"
          route="/brands"
          icon={ <NavBrands /> }
          iconActive={ <NavBrandsActive /> }
        />
        <Li className={ clsx({ active: isSearchBoxOpen }) }>
          <NavLink to={ loc.pathname } replace onClick={ onSearchLinkClick }>
            <NavLinkIcon>{ isSearchBoxOpen ? (<NavSearchActive />) : (<NavSearch />) }</NavLinkIcon>
            <NavLinkLabel>Suche</NavLinkLabel>
          </NavLink>
        </Li>
      </Ul>
    </Nav>
  );
}
