import { createSelector } from '@reduxjs/toolkit';

import { dbBaseSelectors } from '../../../../redux/modules/db';
import { selectFilterRootCategoryId } from './selectFilterRootCategoryId';

export const selectSubCategoriesForRootFilter = createSelector(
  selectFilterRootCategoryId,
  dbBaseSelectors.category.selectAll,
  (rootCategory, categories) => categories
    .filter(c => rootCategory ? c.parent === rootCategory : false)
    .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase())),
);
