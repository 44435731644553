import { createSelector } from '@reduxjs/toolkit';

import { Category } from '../../types';
import { dbBaseSelectors } from '../modules/db';

export const selectCategoryIdsChildrenIdsMap = createSelector(
  dbBaseSelectors.category.selectAll,
  (categories) => {
    const childrenMap: Record<Category['id'], Category['id'][]> = {};
    categories.forEach(category => {
      const parent = category.parent || '';
      if (!childrenMap[parent]) childrenMap[parent] = [];
      childrenMap[parent].push(category.id);
    });
    return childrenMap;
  },
);
