import { createSelector } from '@reduxjs/toolkit';

import { dbBaseSelectors } from '../modules/db';
import { selectDisplayLocationName } from './selectDisplayLocationName';

export const selectCurrentPosition = createSelector(
  dbBaseSelectors.position.selectAll,
  selectDisplayLocationName,
  (positions, name) => positions.find((position) => position.name === name) || positions[0],
);
