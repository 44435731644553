import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import { selectIdeenweltFloor } from '../../../redux/selectors/selectIdeenweltFloor';
import { selectShowOverlays } from '../../../redux/selectors/selectShowOverlays';
import { useAppSelector } from '../../../redux/store';
import { FLOOR, IDEENWELT, IDEENWELT_ROUTE, ImageDimensions } from '../../../types';
import { selectBoothsByFloor } from './selectBoothsByFloor';
import { selectCurrentFloor } from './selectCurrentFloor';

const OverlayContainer = styled.div<{ dimensions: ImageDimensions }>`
  position: absolute;
  width: ${({ dimensions }) => dimensions.W }px;
  height: ${({ dimensions }) => dimensions.H }px;
  top: 0;
  z-index: 10;
`;

const Booth = styled(Link)<{ width?: number, height?: number, top?: number, left?: number, overlay?: string }>`
  position: absolute;
  display: block;
  width: ${({ width }) => (width || 200)}px;
  height: ${({ height }) => (height || 2000)}px;
  top: ${({ top }) => (top || 0)}px;
  left: ${({ left }) => (left || 0)}px;
  background-color: ${({ theme, overlay }) => overlay ? theme.palette.primary.main : 'transparent'};
  opacity: 0.3;
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: .7rem;
`;

export default function BoothAreasOverlay() {
  const isIdeenwelt = !!useRouteMatch(IDEENWELT_ROUTE);
  const floor = useAppSelector(isIdeenwelt ? selectIdeenweltFloor : selectCurrentFloor);
  const booths = useAppSelector(state => selectBoothsByFloor(state, floor?.id || ''));
  const imageDimensions = isIdeenwelt ? IDEENWELT : FLOOR;
  const factor = imageDimensions.W/(floor?.width || imageDimensions.W);
  const showOverlays = useAppSelector(selectShowOverlays);

  return (
    <OverlayContainer dimensions={ imageDimensions }>
      {
        booths.map((booth, i) => (
          <Booth to={ `/exhibitors/${booth.exhibitor.id}` } key={ i }
            width={ (booth.area?.width || 0) * factor }
            height={ (booth.area?.height || 0) * factor }
            top={ (booth.area?.coordinate_y || 0) * factor }
            left={ (booth.area?.coordinate_x || 0) * factor }
            overlay={ showOverlays ? 'true' : '' }
          >
            { showOverlays &&
              <>{ booth.area?.name } <br /> { booth.exhibitor?.name }</>
            }
          </Booth>
        ))
      }
    </OverlayContainer>
  );
}
