import { createSelector } from '@reduxjs/toolkit';

import { selectFilterCategoryIds } from '../../../../redux/selectors/selectFilterCategoryIds';
import { selectRootCategories } from '../../../../redux/selectors/selectRootCategories';

export const selectFilterRootCategoryId = createSelector(
  selectRootCategories,
  selectFilterCategoryIds,
  (categories, filters) => {
    const rootIds = categories.map(c => c.id);
    return filters.find(c => rootIds.includes(c));
  },
);
