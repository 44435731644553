import { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { showSearchBox } from '../../app/redux/modules/app';
import { dbBaseSelectors } from '../../app/redux/modules/db';
import { useAppDispatch, useAppSelector } from '../../app/redux/store';
import Heading from '../../app/view/components/Heading';
import BrandTile from '../BrandsPage/BrandTile';
import DirectusImage from '../directus/DirectusImage';
import useFile from '../directus/useFile';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 911px;
  font-size: 1.6rem;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  margin-top: 66px;
  margin-bottom: 50px;
`;

const MapImage = styled(DirectusImage)`
  width: 518px;
  margin: 0;
`;

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 308px;
`;

const ExhibitorLogoTop = styled(DirectusImage)`
  width: 308px;
  margin: 0 0 60px;
  border: 1px solid black;
  object-fit: contain;
`;

const ExhibitorLogoBottom = styled(ExhibitorLogoTop)`
  margin-bottom: 10px;
`;

const SideHeading = styled(Heading)`
  margin: 0 0 30px;
  white-space: nowrap;
`;

const BrandsContainer = styled.div<{ large: boolean }>`
  height: ${({ large }) => large ? '734px' : '540px' };
  overflow-y: auto;
`;

const SideBrandTile = styled(BrandTile)`
  height: 100px;
  margin-bottom: 36px;
`;

const WebsiteContainer = styled.div`
  position: fixed;
  bottom: 290px;
  right: 85px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: ${({ theme }) => theme.palette.common.white};
  color: ${({ theme }) => theme.palette.common.grayText};
  padding: 10px 0 5px 10px;
`;

const ContentWrapper = styled.div`
  max-height: 310px;
  overflow-y: auto;
`;

const ContentHeadline = styled.p`
  font-weight: bold;
  margin-bottom: 0;
`;

const Markdown = styled(ReactMarkdown)<{ isNarrow: boolean }>`
  padding-right: ${ ({ isNarrow }) => isNarrow ? '320px' : '0' };
`;

export default function ExhibitorDetailsPage() {
  const dispatch = useAppDispatch();
  const { exhibitorId } = useParams<{ exhibitorId: string }>();
  const exhibitor = useAppSelector(state => dbBaseSelectors.exhibitor.selectById(state, exhibitorId));
  const map = useFile(exhibitor?.map_image);
  const logo = useFile(exhibitor?.logo_image);

  useEffect(() => {
    dispatch(showSearchBox(false));
  }, []);

  return (
    <PageContainer>
      <TopContainer>
        <MapImage asset={ map.file } fallbackSrc="https://picsum.photos/seed/picsum/518/804" />
        <SidebarContainer>
          { exhibitor?.layout !== 'many_brands' &&
              <ExhibitorLogoTop asset={ logo.file } fallbackSrc="https://picsum.photos/seed/picsum/308/128" />
          }
          { exhibitor?.layout !== 'no_brands' && !!exhibitor?.brands?.length &&
            <>
              <SideHeading level={ 4 }>Verfügbare Marken</SideHeading>
              <BrandsContainer large={ exhibitor?.layout === 'many_brands' }>
                {
                  exhibitor?.brands?.map(({ brand_id }) => (<SideBrandTile key={ brand_id } id={ brand_id } />))
                }
              </BrandsContainer>
            </>
          }
        </SidebarContainer>
      </TopContainer>
      <Heading level={ 2 }>{ exhibitor?.name }</Heading>
      <ContentWrapper>
        { exhibitor?.headline && <ContentHeadline>{ exhibitor?.headline }</ContentHeadline> }
        <Markdown isNarrow={ exhibitor?.layout === 'many_brands' } className="test">
          { exhibitor?.description || '' }
        </Markdown>
      </ContentWrapper>
      <WebsiteContainer>
        { exhibitor?.layout === 'many_brands' &&
          <ExhibitorLogoBottom asset={ logo.file } fallbackSrc="https://picsum.photos/seed/picsum/308/128" />
        }
        <span>{ exhibitor?.website }</span>
      </WebsiteContainer>
    </PageContainer>
  );
}
