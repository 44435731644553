import clsx from 'clsx';
import { useEffect } from 'react';
import styled from 'styled-components';

import { setFloor } from '../../app/redux/modules/app';
import { selectCurrentPosition } from '../../app/redux/selectors/selectCurrentPosition';
import { selectMainFloors } from '../../app/redux/selectors/selectMainFloors';
import { useAppDispatch, useAppSelector } from '../../app/redux/store';

const Nav = styled.nav`
  display: block;
  background-color: ${({ theme }) => theme.palette.common.grayBg};
  position: fixed;
  right: 0;
  top: 50vh;
  transform: translateY(-50%);
  border-radius: 20px 0 0 20px;
  overflow: hidden;
`;

const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0;
  padding: 0;
`;

const Li = styled.li`
  display: block;
  margin: 0 .8em;
  border-top: 4px solid ${({ theme }) => theme.palette.common.white};

  &:first-child {
    border: none;
  }

  &.active {
    background-color: ${({ theme }) => theme.palette.primary.main};
    margin: 0 0 -4px;
    padding: 4px .8em 4px;
    z-index: 1;
    border: none;

    &:first-child {
      padding-top: 0;
    }
  }
`;

const Button = styled.button`
  background: none;
  border: none;
  margin: 0;
  width: 100%;
  font-family: inherit;
  display: block;
  font-style: normal;
  text-decoration: none;
  text-transform: uppercase;
  padding: .8em .3em;
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  text-align: right;
  
  &:focus,
  &:active {
    outline: none;
  }
`;

export default function FloorNavigation() {
  const floors = useAppSelector(selectMainFloors);
  const position = useAppSelector(selectCurrentPosition);
  const dispatch = useAppDispatch();
  const selectedFloor = useAppSelector(state => state.app.selectedFloor);

  const handleClick = (id?: string) => id && dispatch(setFloor(id));

  useEffect(() => {
    if (selectedFloor || !position?.floor) return;
    dispatch(setFloor(position?.floor));
  }, [selectedFloor, position]);

  return (
    <Nav>
      <Ul>
        {
          floors?.map(({ name, id }) => {
            return (
              <Li key={ id } className={ clsx({ active: selectedFloor === id }) }>
                <Button onClick={ () => handleClick(id) }>{ name }</Button>
              </Li>
            );
          })
        }
      </Ul>
    </Nav>
  );
}
