import { createSelector } from '@reduxjs/toolkit';
import { uniq } from 'lodash';

import { RootState } from '../store';
import { selectFilteredExhibitors } from './selectFilteredExhibitors';

export const selectExhibitorsLetters = createSelector(
  (state: RootState) => selectFilteredExhibitors(state, { disableFirstLetter: true }),
  (exhibitors) => uniq(exhibitors.map(({ name }) => name.charAt(0).toLocaleLowerCase()))
    .sort((a, b) => a.localeCompare(b)),
);
