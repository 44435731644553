import clsx from 'clsx';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { setFilterCategoryIds } from '../../../../redux/modules/app';
import { selectFilterCategoryIds } from '../../../../redux/selectors/selectFilterCategoryIds';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { Category } from '../../../../types';
import { selectSubCategoriesForRootFilter } from './selectSubCategoriesForRootFilter';
import { ReactComponent as TopicDown } from './topic-down.svg';
import { ReactComponent as TopicFilter } from './topic-filter.svg';
import { ReactComponent as TopicUp } from './topic-up.svg';

const FilterContainer = styled.div`
  position: relative;
  width: 75.5%;
  background-color: ${({ theme }) => theme.palette.common.grayBg};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin: 0 auto;
  padding: 1rem 1.5rem;
  color: ${({ theme }) => theme.palette.common.white};
`;

const FilterHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 2rem;

  svg {
    margin-right: 1rem;
    position: relative;
    top: 4px;
  }
`;

const Button = styled.button`
  display: inline-block;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  background-color: ${({ theme }) => theme.palette.secondary.main};
  border: 1px solid ${({ theme }) => theme.palette.common.darkText};
  font-size: 2rem;
  padding: 0.5rem 1rem;
  margin: 0 4px 4px 0;
  border-radius: 4px;

  &.active {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ExpColBtn = styled.span`
  border: none;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const COLLAPSED_FILTER_COUNT = 3;

export default function SubcategoryFilter() {
  const dispatch = useAppDispatch();
  const subCategories = useAppSelector(selectSubCategoriesForRootFilter);
  const filterCategoryIds = useAppSelector(selectFilterCategoryIds);

  const [showMore, setShowMore] = useState(false);

  const handleFilter = (categoryId: Category['id']) => {
    if (!categoryId) return;
    // use _.xor for multiselect
    // const filtered = xor(filterCategoryIds, [categoryId]);
    // dispatch(setFilterCategoryIds(filtered));

    // use this for single select subcategories
    const subCategoryIds = subCategories.map(({ id }) => id);
    const filtered = filterCategoryIds.filter(id => !subCategoryIds.includes(id));
    dispatch(setFilterCategoryIds([...filtered, categoryId]));
  };

  const visibleSubCategories = useMemo(() => {
    if (showMore) return subCategories;
    return subCategories.slice(0, COLLAPSED_FILTER_COUNT);
  }, [showMore, subCategories]);

  if (!filterCategoryIds.length || !subCategories.length) return null;

  return (
    <FilterContainer>
      <FilterHeading>
        <TopicFilter width={ 48 } height={ 48 }/>
        konkretisieren Sie Ihre Auswahl
      </FilterHeading>
      { showMore && <ExpColBtn onClick={ () => setShowMore(false) }><TopicDown/></ExpColBtn> }
      { !showMore && <ExpColBtn onClick={ () => setShowMore(true) }><TopicUp/></ExpColBtn> }
      <ButtonContainer>
        {
          visibleSubCategories.map(({ name, id }) => (
            <Button key={ id } className={ clsx({ active: filterCategoryIds.includes(id) }) }
              onClick={ () => handleFilter(id) }
            >{ name }</Button>),
          )
        }
        { !showMore && <Button onClick={ () => setShowMore(s => !s) }>...</Button> }
      </ButtonContainer>
    </FilterContainer>
  );
}
