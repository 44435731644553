import styled from 'styled-components';

import useShowScrollButtons from '../../app/hooks/useShowScrollButtons';
import { selectBrandsLetters } from '../../app/redux/selectors/selectBrandsLetters';
import { useAppSelector } from '../../app/redux/store';
import AlphabetFilter from '../../app/view/components/AlphabetFilter';
import Heading from '../../app/view/components/Heading';
import BrandList from './BrandList';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 911px;
  flex: 1 0 auto;
  padding-top: 30px;
`;

const BrandsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export default function BrandsPage() {
  const letters = useAppSelector(selectBrandsLetters);
  useShowScrollButtons(true);

  return (
    <>
      <PageContainer>
        <Heading level={ 2 }>
          Finden Sie die Marke ihrer Wahl
        </Heading>

        <BrandsWrapper>
          <BrandList />
        </BrandsWrapper>
      </PageContainer>

      <AlphabetFilter letters={ letters } />
    </>
  );
}
