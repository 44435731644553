import { Link } from 'react-router-dom';
import styled from 'styled-components';

import FloorNavigation from './FloorNavigation';
import HomeMap from './HomeMap';
import { ReactComponent as HomeEyeCatcherSvg } from './start_stoerer.svg';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 911px;
  flex: 1 1 100%;
  justify-content: center;
  align-items: center;
`;

const OverviewLink = styled(Link)`
  position: absolute;
  top: 937px;
  left: 25px;
  width: 238px;
  height: 238px;
  text-decoration: none;
  z-index: 20;
`;

const HomeEyeCatcher = styled(HomeEyeCatcherSvg)`
  width: 100%;
`;

export default function HomePage() {
  return (
    <PageContainer>
      <FloorNavigation />
      <HomeMap />
      <OverviewLink to="/floors">
        <HomeEyeCatcher />
      </OverviewLink>
    </PageContainer>
  );
}
