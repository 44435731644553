import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { setFilterFirstLetter } from '../../redux/modules/app';
import { selectFilterFirstLetter } from '../../redux/selectors/selectFilterFirstLetter';
import { selectShowSearchBox } from '../../redux/selectors/selectShowSearchBox';
import { useAppDispatch, useAppSelector } from '../../redux/store';

interface Props {
  letters: string[];
}

const Container = styled.div`
  position: sticky;
  bottom: 0;
  width: 911px;
  display: flex;
  padding: 10px 0 5px;
  background: ${({ theme }) => theme.palette.common.white};
  justify-content: center;
`;

const Button = styled.button<{ active?: boolean }>`
  background: #ffffff;
  text-transform: uppercase;
  font-size: 1.2rem;
  padding: 5px;
  margin: 5px;
  flex: 1 1 50px;
  border: 1px solid ${({ theme }) => theme.palette.common.grayText};
  border-radius: ${({ theme }) => theme.borderRadius};
  max-width: 50px;
  ${({ active, theme }) => active && `
    background: ${theme.palette.primary.main};
    color: ${theme.palette.primary.contrastText};
    border-color: ${theme.palette.primary.main};
  `}

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export default function AlphabetFilter({ letters }: Props) {
  const dispatch = useAppDispatch();
  const firstLetter = useAppSelector(selectFilterFirstLetter);

  const isSearchBoxOpen = useSelector(selectShowSearchBox);

  const filterByFirstLetter = (firstLetter: string) => {
    dispatch(setFilterFirstLetter(firstLetter));
  };

  if (isSearchBoxOpen) {
    return null;
  }

  return (
    <Container>
      {
        letters.map(letter =>
          <Button
            key={ letter }
            onClick={ () => filterByFirstLetter(letter) }
            active={ letter === firstLetter }
          >{ letter }</Button>,
        )
      }
    </Container>
  );
}
