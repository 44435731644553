import styled from 'styled-components';

import useShowScrollButtons from '../../app/hooks/useShowScrollButtons';
import { selectExhibitorsLetters } from '../../app/redux/selectors/selectExhibitorsLetters';
import { selectMainFloorIds } from '../../app/redux/selectors/selectMainFloorIds';
import { useAppSelector } from '../../app/redux/store';
import { Floor } from '../../app/types';
import AlphabetFilter from '../../app/view/components/AlphabetFilter';
import Heading from '../../app/view/components/Heading';
import ExhibitorsList from './ExhibitorsList';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 911px;
  flex: 1 0 auto;
`;

const FloorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
`;

const FloorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export default function ExhibitorsPage() {
  const floorIds = useAppSelector(selectMainFloorIds);
  const letters = useAppSelector(selectExhibitorsLetters);

  useShowScrollButtons(true);

  return (
    <>
      <PageContainer>
        <FloorsContainer>
          <Heading level={ 2 }>Überblick Alle Etagen</Heading>
          <FloorsWrapper>
            {
              floorIds.map(id => (<ExhibitorsList key={ id } floorId={ id as Floor['id'] } />))
            }
          </FloorsWrapper>
        </FloorsContainer>
      </PageContainer>
      <AlphabetFilter letters={ letters } />
    </>
  );
}
