import { memo, PropsWithChildren } from 'react';
import styled from 'styled-components';

interface Props {
  level?: number;
  className?: string;
}

const H1 = styled('h1')`
  font-size: 2rem;
  font-weight: 300;
  color: ${({ theme }) => theme.palette.common.darkText};
  text-transform: uppercase;
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.darkText};
`;

const H2 = styled('h2')`
  font-size: 2rem;
  font-weight: 300;
  color: ${({ theme }) => theme.palette.common.darkText};
  text-transform: uppercase;
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.darkText};
`;

const H3 = styled('h3')`
  font-size: 2rem;
  font-weight: 300;
  color: ${({ theme }) => theme.palette.common.darkText};
  text-transform: uppercase;
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.darkText};
`;

const H4 = styled('h4')`
  font-size: 2rem;
  font-weight: 300;
  color: ${({ theme }) => theme.palette.common.darkText};
  text-transform: uppercase;
`;

const H5 = styled('h5')`
  font-size: 2rem;
  font-weight: 300;
  color: ${({ theme }) => theme.palette.common.darkText};
  text-transform: uppercase;
`;

const H6 = styled('h6')`
  font-size: 2rem;
  font-weight: 300;
  color: ${({ theme }) => theme.palette.common.darkText};
  text-transform: uppercase;
`;

function Heading({ children, level = 1, className }: PropsWithChildren<Props>) {
  switch (level) {
    case 1: return <H1 className={ className }>{ children }</H1>;
    case 2: return <H2 className={ className }>{ children }</H2>;
    case 3: return <H3 className={ className }>{ children }</H3>;
    case 4: return <H4 className={ className }>{ children }</H4>;
    case 5: return <H5 className={ className }>{ children }</H5>;
    default: return <H6 className={ className }>{ children }</H6>;
  }
}

export default memo(Heading);
