import { useEffect, useState } from 'react';

import { PELocation } from '../../peTypes';
import { setDislayLocationName } from '../../redux/modules/app';
import { useAppDispatch } from '../../redux/store';

export default function useLoadDisplayLocation() {
  const dispatch = useAppDispatch();
  const [displayLocation, setDisplayLocation] = useState<PELocation>();

  useEffect(() => {
    window.addEventListener('message', (event: MessageEvent<{ displayLocation: PELocation }>) => {
      if (event.data?.displayLocation) {
        setDisplayLocation(event.data.displayLocation);
        dispatch(setDislayLocationName(event.data?.displayLocation?.name || ''));
      }
    });

    window.parent.postMessage({ command: 'getDisplayLocation' }, '*');
  }, [dispatch]);

  return displayLocation;
}
