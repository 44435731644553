import { createSelector } from '@reduxjs/toolkit';
import { intersection } from 'lodash';

import { dbBaseSelectors } from '../modules/db';
import { RootState } from '../store';
import { selectFilterFirstLetter } from './selectFilterFirstLetter';
import { selectImplicitFilterCategoryIds } from './selectImplicitFilterCategoryIds';

type FilterOptions = {
  disableFirstLetter?: boolean;
  disableCategory?: boolean;
} | undefined | null;

export const selectFilteredExhibitors = createSelector(
  dbBaseSelectors.exhibitor.selectAll,
  selectFilterFirstLetter,
  selectImplicitFilterCategoryIds,
  (_: RootState, options: FilterOptions) => options,
  (exhibitors, firstLetter, categoryIds, options) => exhibitors
    .filter(({ name }) => {
      return (!!options?.disableFirstLetter || !firstLetter) ? true : name.charAt(0).toLocaleLowerCase() === firstLetter;
    })
    .filter(({ categories }) => {
      return (!!options?.disableCategory || !categoryIds.length)
        ? true
        : !!intersection(categories?.map(c => c.category_id), categoryIds).length;
    }),
);
