import { useCallback } from 'react';

import { setFilterCategoryIds, setFilterFirstLetter, setFilterSearchQuery, setFloor, showSearchBox } from '../redux/modules/app';
import { selectCurrentPosition } from '../redux/selectors/selectCurrentPosition';
import { useAppDispatch, useAppSelector } from '../redux/store';

export default function useClearFilters () {
  const dispatch = useAppDispatch();
  const floorId = useAppSelector(selectCurrentPosition)?.floor;

  return useCallback(() => {
    dispatch(setFilterFirstLetter(''));
    dispatch(setFilterCategoryIds([]));
    dispatch(setFilterSearchQuery(''));
    dispatch(showSearchBox(false));
    if (floorId) dispatch(setFloor(floorId));
  }, [ dispatch, floorId ]);

}
